<template>
  <div class="shop">
    <left-tabs :tabList="leftList" />
    <keep-alive include="shoplist">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: { LeftTabs },
  name: "shop",
  data() {
    return {
      leftList: [
        { id: 1, name: "门店列表", url: "/shop/list" },
        { id: 2, name: "门店角色", url: "/shop/role" },
        { id: 3, name: "用户列表", url: "/shop/user" },
        { id: 4, name: "门店类型", url: "/shop/type" },
        { id: 5, name: "门店投诉", url: "/shop/shopcomplaint" },
      ],
    };
  },
  methods: {},
  created(){
	  // this.$router.push("/shop/list");
  },
};
</script>

<style scoped lang='less'>
.shop { 
  display: flex;
}
</style>